import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { Card, CardActionArea } from "@material-ui/core";

const OfferCard = ({ title, slug, offerImage, publishDate }) => {
  return (
    <Card>
      <CardActionArea href={`/offers/${slug}`}>
        {offerImage && <Img {...offerImage} />}
      </CardActionArea>
    </Card>
  );
};

export default OfferCard;

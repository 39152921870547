import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import OfferCard from "../components/OfferCard";

import ListingPage from "../layouts/ListingPage";

const Offers = (props) => {
  const siteTitle = get(props, "propsdata.site.siteMetadata.title");
  const posts = get(props, "data.allContentfulOffers.edges");

  return (
    <ListingPage
      title={"Our offers"}
      siteTitle={siteTitle}
      backgroundImageUrl={"/banner.jpg"}
      location={props.location}
      items={posts}
      itemsPerRow={2}
      component={OfferCard}
    />
  );
};

export default Offers;

export const pageQuery = graphql`
  query OffersQuery {
    allContentfulOffers {
      edges {
        node {
          title
          slug
          offerImage {
            fluid(maxWidth: 350, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`;
